// NOTE: change content according to form purpose.

const submitMessages = {
  // Slovene
  sl: {
    success: 'Hvala vam za poslano povpraševanje! Naša ekipa se bo oglasila v najkrajšem možnem času.',
    errorGeneral: 'Prišlo je do napake, poskusite še enkrat.',
  },
  // English
  en: {
    success: 'Thank you for reaching out! We appreciate your message and will get back to you as fast as possible. Have a great day!',
    errorGeneral: 'An error has occurred, please try again later.',
  },
};

export default submitMessages;
