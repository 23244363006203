import { CountUp } from 'countup.js';

export default function counterInit() {
  const counters = document.querySelectorAll('.js-counter');

  counters.forEach((counter) => {
    let options = {
      startVal: 0,
      decimalPlaces: 0,
      useGrouping: true,
      duration: 3,
      useEasing: false,
      separator: ',',
      decimal: '.',
      prefix: '',
      sufix: '',
    };

    const startValue = counter.getAttribute('data-start-value');
    if (startValue) {
      options = {
        ...options,
        startVal: startValue,
      };
    }

    const endValue = counter.getAttribute('data-end-value');

    const duration = counter.getAttribute('data-duration');
    if (duration) {
      options = {
        ...options,
        duration,
      };
    }

    // eslint-disable-next-line no-unused-vars
    const singleCounter = new CountUp(counter, endValue, options);

    if (!singleCounter.error) {
      const observerTarget = document.querySelector('.js-counter-observer-target');
      const isHomePage = document.querySelector('body').classList.contains('home');

      if (observerTarget && isHomePage) {
        const handler = (entries) => {
          if (!entries[0].isIntersecting) {
            singleCounter.pauseResume();
          } else {
            singleCounter.start();
          }
        };

        const observerOptions = {
          rootMargin: '0px',
          threshold: 1.0,
        };

        const observer = new IntersectionObserver(handler, observerOptions);

        observer.observe(observerTarget);
      } else {
        singleCounter.start();
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(singleCounter.error);
    }
  });
}
