import colorListInit from '../../../utils/colors-list';
import counterInit from '../../components/counter/counter';
import selectInit from '../../components/form/select/select';
import checkboxRadioInit from '../../components/form/checkbox-radio/checkbox-radio';
// import Youtube from '../../components/youtube/youtube';
// import sliderInit from '../../components/slider/slider';
import scrollbarInit from '../../components/scrollbar/scrollbar';
// import showMoreInit from '../../components/show-more/show-more';
// import modalInit from '../../components/modal/modalInit';
// import datePickerInit from '../../components/form/date-picker/date-picker';
import formInit from './form';
import CookieConsent from '../../components/cookie-consent/cookie-consent';
import countdownTimer from '../../components/countdown/countdown';
import scrollTo from '../../components/scroll-to/scroll-to';

colorListInit();
counterInit();
selectInit();
checkboxRadioInit();
// sliderInit();
scrollbarInit();
// showMoreInit();
// modalInit();
// datePickerInit();
formInit();
countdownTimer();
scrollTo();

// eslint-disable-next-line no-unused-vars
// const youtube = new Youtube();

// Cookie consent
(async () => {
  const cookieOptions = await import(`../../components/cookie-consent/cookie-options-${document.documentElement.lang}.json`);
  // eslint-disable-next-line no-unused-vars
  const consent = new CookieConsent(cookieOptions);
})();
