export default function countdownTimer() {
  const countDownDate = new Date('2023-07-01').getTime();

  const countdown = setInterval(() => {
    const now = new Date().getTime();

    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    document.querySelector('.countdown-days').innerHTML = days;
    document.querySelector('.countdown-hours').innerHTML = hours;
    document.querySelector('.countdown-minutes').innerHTML = minutes;
    document.querySelector('.countdown-seconds').innerHTML = seconds;

    if (distance < 0) {
      clearInterval(countdown);
      document.querySelector('.countdown-days').innerHTML = '0';
      document.querySelector('.countdown-hours').innerHTML = '0';
      document.querySelector('.countdown-minutes').innerHTML = '0';
      document.querySelector('.countdown-seconds').innerHTML = '0';
    }
  }, 1000);
}
