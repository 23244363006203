export default function scrollTo() {
  function scrollToElement() {
    const scrollSection = document.getElementById('scroll-to');
    scrollSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  function scrollToForm() {
    const scrollSection = document.querySelector('.scroll-to-form');
    scrollSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const scrollButton = document.querySelector('.scroll-down');
  const formButtons = document.querySelectorAll('.form-button');

  scrollButton.addEventListener('click', () => {
    scrollToElement();
  });

  formButtons.forEach((button) => {
    button.addEventListener('click', () => {
      scrollToForm();
    });
  });
}
